import React from "react";
import Mobile from "./mobile";
import Desktop from "./desktop";

const Footer = (props) => {

	const prop = props;

	const data = {
		...prop,
		type: prop.title || "TITLE",
		color: prop.color || "#D42428",
		colorFont: prop.colorFont || "#000",
		isLine: prop.isLine || false,
	};

	if (prop.isMobile) {

		return (
			<Mobile
				{...data}
			/>
		);

	}
	return (
		<Desktop {...data} />
	);

};

export default Footer;
