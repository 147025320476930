import React from "react";
import Desktop from "./desktop";
import Mobile from "./mobile";

const DateContainer = (props) => {

	const prop = props;

	if (prop.isMobile) {

		return (
			<Mobile
				{...prop}
			/>
		);

	}
	return (
		<Desktop {...prop} />
	);

};

export default DateContainer;
