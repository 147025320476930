/* eslint-disable no-nested-ternary */
import React from "react";
import dynamic from "next/dynamic";
import Banner from "Components/banner/banner";
import Pagination from "@fdn/pagination";

const ShimmerData = dynamic(
	() => import("Components/shimmer/article_list"),
);

const ErrContainer = dynamic(
	() => import("Pages/404"),
	{ ssr: false },
);

const ArticleCard = dynamic(
	() => import("Components/article_card"),
);

const mobile = ({ ...props }) => {

	const prop = props;
	return (
		<div className="page-container" id="card-top">
			<Banner
				component_id="billboard"
				placeholder="staticbanner1"
				name="billboard"
				isMobile={prop.isMobile}
				class_name="ads-billboard"
			/>
			{
				prop.ArticleData.status === "fetch_loading"
					? 									(
						<ShimmerData />

					)
					: prop.ArticleData?.data?.data?.length > 0
						? (
							<div className="content">
								<h1 className="title-text" data-title="">
									{prop.Date}
								</h1>
								<div className="list-article">
									{
										prop.ArticleData.data.data.slice(0, 5).map((item, idx) => (
											<div className={idx !== 0 ? "small line" : "small"} key={item.id}>
												<ArticleCard
													isMobile={prop.isMobile}
													type={idx === 0
														? "DEFAULT" : "SMALL"}
													title={item.title}
													category={
														item.category ? item.category[0].name : null
													}
													catSlug={
														item.category ? item.category[0].slug : null
													}
													image={item.image}
													author={item.author}
													date={item.created_at}
													slug={item.slug}
												/>
											</div>
										))
									}
								</div>
								<div className="dual-article">

									{

										prop.ArticleData?.data?.data.slice(5, 7).map((item) => (
											<div className="small" key={item.id}>
												<ArticleCard
													isMobile={prop.isMobile}
													type="MEDIUM-1"
													title={item.title}
													category={
														item.category ? item.category[0].name : null
													}
													catSlug={
														item.category ? item.category[0].slug : null
													}
													image={item.image}
													author={item.author}
													date={item.created_at}
													slug={item.slug}
												/>
											</div>
										))
									}
								</div>
								<div className="list-article">
									{
										prop.ArticleData.data.data.slice(7, 10).map((item) => (
											<div className="small line-bottom" key={item.id}>
												<ArticleCard
													isMobile={prop.isMobile}
													type="SMALL"
													title={item.title}
													category={
														item.category ? item.category[0].name : null
													}
													catSlug={
														item.category ? item.category[0].slug : null
													}
													image={item.image}
													author={item.author}
													date={item.created_at}
													slug={item.slug}
												/>
											</div>
										))
									}
								</div>
								<div className="pagination">
									<Pagination
										is_mobile
										page={prop.ArticleData.data?.pagination?.page || 1}
										total_page={
											Math.ceil(prop.ArticleData.data?.pagination?.total
										/ prop.ArticleData.data?.pagination?.limit)
										}
										update_page={prop.updatePagination}
										scroll_id="card-top"
									/>
								</div>
							</div>
						)
						: <ErrContainer />
			}
			<Banner
				name="staticbanner1"
				placeholder="staticbanner2"
				isMobile={prop.isMobile}
				class_name="ads-static-3"
			/>

			<style jsx>
				{`
					.title-text {
						text-shadow: 2px 2px #DFE3E8;
						font-family: 'Brygada 1918';
						font-style: italic;
						font-weight: 700;
						font-size: 24px;
						line-height: 12px;
						letter-spacing: 0em;
						text-align: center;
						position: relative;
						text-transform: capitalize;
						color: #000;
						margin: 30px 16px;
					}
					.title-text::before {
						position: absolute;
						content: attr(data-title);
						color: transparent;
						z-index: -1;
						top: 9px;
						height: 14px;
						left: 0px;
						width: 100%;
						background: #EDAAB9;
					} 
					.title-text::after {
						position: absolute;
						content: attr(data-title);
						color: transparent;
						z-index: -2;
						height: 18px;
						left: 0px;
						top: 9px;
						width: 100%;
						background: #EEE8E8;
					} 
					.loader {
						display: flex;
						justify-content: center;
						margin: 20px 0;
					}
					.pagination {
						margin: 16px;
					}
          .list-article {
            margin: 16px;
          }
          .small {
            border: none;
            padding: 16px 0;
          }
          .line {
						border-top: 0.5px solid #e0e0e0;
					}
					.line-bottom {
						border-bottom: 0.5px solid #e0e0e0;
					}
          .content {
            // margin: 16px;
          }
          .page-container {
						max-width: 480px;
						margin: 16px auto;
					}
					.dual-article {
						background: #F0F3F7;
						padding: 0 16px;
            margin: 16px 0 14px 0;

						display: grid;
						grid-column-gap: 20px;
						grid-row-gap: 20px;
						grid-template-columns: repeat(2, 1fr);
          }
        `}
			</style>
		</div>
	);

};

export default mobile;
