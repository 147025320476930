import React from "react";

const Mobile = ({ ...props }) => {

	const prop = props;
	return (
		<div className="title-section">
			{prop.isLine
			&& <hr className="hr-title" />}
			<p className="title-text" data-title={prop.isBackground ? prop.title : ""}>{prop.title}</p>

			<style jsx>

				{`
        
        .title-text {
          font-family: 'Brygada 1918', serif;
          font-weight: 700;
          font-size: 24px;
          line-height: 12px;
          font-style: italic;
          letter-spacing: 0em;
          text-align: left;
          margin: ${prop.isLine ? "0" : "0 0 0 60px"};
          position: relative;
          color: ${prop.colorFont};
        }
        .title-text::before {
          position: absolute;
          content: attr(data-title);
          color: transparent;
          z-index: -1;
          top: 4px;
          left: 0px;
          width: 102%;
          background: ${prop.color};
        } 
        .title-text::after {
          position: absolute;
          content: attr(data-title);
          color: transparent;
          z-index: -2;
          top: 8px;
          left: 2px;
          width: 102%;
          background: #E8E8E8;
        } 
        .title-section {
          display: flex;
          align-items: center;
          margin: 15px 0;
        }
        hr.hr-title {
          border-top: 0.5px solid ${prop.color};
          width: 48px;
          margin: 0 10px 0 0;
        }
        `}
			</style>
		</div>
	);

};

export default Mobile;
