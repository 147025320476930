/* eslint-disable no-nested-ternary */
import Pagination from "@fdn/pagination";
import Banner from "Components/banner/banner";
import TitleSection from "Components/title_section";
import dynamic from "next/dynamic";

const ShimmerData = dynamic(
	() => import("Components/shimmer/article_list_desktop"),
);

const ErrContainer = dynamic(
	() => import("Pages/404"),
	{ ssr: false },
);

const ArticleCard = dynamic(
	() => import("Components/article_card"),
);

const Desktop = ({ ...props }) => {

	const prop = props;

	return (
		<div className="page-container" id="card-top">
			<Banner
				component_id="billboard"
				placeholder="billboard"
				name="billboard"
				is_mobile={prop.isMobile}
				class_name="ads-billboard"
			/>
			{
				prop.ArticleData.status === "fetch_loading"
					? 									(
						<ShimmerData />
					)
					: prop.ArticleData?.data?.data?.length > 0
						? (
							<div className="content">
								<div className="text-container" id="card-top">
									<h1 className="title-text" data-title="">{prop.Date}</h1>
								</div>

								<div className="container-article">
									<div className="list-article">
										{
											prop.ArticleData.data?.data?.map((item, idx) => (
												<div className={idx !== 0 ? "small line" : "small"} key={item.id}>
													<ArticleCard
														isDate
														isImage
														isMobile={prop.isMobile}
														type="SMALL"
														title={item.title}
														width="250px"
														height="168px"
														fontSize="20px"
														category={
															item.category ? item.category[0].name : null
														}
														catSlug={
															item.category ? item.category[0].slug : null
														}
														image={item.image}
														author={item.author}
														date={item.created_at}
														slug={item.slug}
													/>
												</div>
											))
										}
									</div>
									<div className="right-side">

										<Banner
											name="mediumrectangle1"
											placeholder="mediumrectangle2"
											class_name="ads-mediumrectangle1"
											isMobile={prop.isMobile}
										/>
										<div className="popular-article">
											<div className="title">
												<TitleSection widthLine="59px" fontSize="24px" isMobile={prop.isMobile} title="POPULAR ARTICLE" isLine />
											</div>
											{
												prop.ArticlePopular?.data?.data?.slice(0, 4).map((item) => (
													<div className="small line-bottom-bold" key={item.id}>
														<ArticleCard
															isDate
															isMobile={prop.isMobile}
															type="SMALL"
															title={item.title}
															width="120px"
															height="120px"
															fontSize="16px"
															category={
																item.category_slug ? item.category_slug.name : null
															}
															catSlug={
																item.category_slug ? item.category_slug.slug : null
															}
															image={item.image}
															author={item.author}
															date={item.created_at}
															slug={item.slug}
														/>
													</div>
												))
											}
											<Banner
												name="mediumrectangle2"
												placeholder="mediumrectangle2"
												class_name="ads-mediumrectangle2-detail"
												isMobile={prop.isMobile}
											/>
											<Banner
												name="mediumrectangle3"
												placeholder="mediumrectangle2"
												class_name="ads-mediumrectangle3"
												isMobile={prop.isMobile}
											/>
										</div>
									</div>
								</div>

								<div className="pagination">
									<Pagination
										is_mobile={prop.isMobile}
										page={prop.ArticleData.data?.pagination?.page || 1}
										total_page={
											Math.ceil(prop.ArticleData.data?.pagination?.total
										/ prop.ArticleData.data?.pagination?.limit)
										}
										update_page={prop.updatePagination}
										scroll_id="card-top"
									/>
								</div>

							</div>
						)
						: <ErrContainer />
			}
			<Banner
				name="staticbanner1"
				placeholder="staticbanner2"
				isMobile={prop.isMobile}
				class_name="ads-static-3"
			/>

			<style jsx>
				{`
					.breadcrumb {
						margin: 16px;
					}

					.text-container {
						width: 100%;
						display: flex;
						justify-content: center;
					}

					.title-text {
						width: 430px;
						text-shadow: 2px 2px #DFE3E8;
						font-family: 'Brygada 1918';
						font-style: italic;
						font-weight: 700;
						font-size: 32px;
						line-height: 12px;
						letter-spacing: 0em;
						text-align: center;
						position: relative;
						text-transform: capitalize;
						color: #000;
						margin: 30px 16px;
					}

					.title-text::before {
						position: absolute;
						content: attr(data-title);
						color: transparent;
						z-index: -1;
						top: 9px;
						height: 14px;
						left: 0px;
						width: 100%;
						background: #EDAAB9;
					} 

					.title-text::after {
						position: absolute;
						content: attr(data-title);
						color: transparent;
						z-index: -2;
						height: 18px;
						left: 0px;
						top: 9px;
						width: 100%;
						background: #EEE8E8;
					} 

					.loader {
						display: flex;
						justify-content: center;
						margin: 20px 0;
					}

					.pagination {
						margin: 80px 16px;
					}

					.container-article	{
						display: flex;
						margin: 56px 0 80px 0 ;
						justify-content: space-between;
					}

          .list-article {
            margin: 16px;
						max-width: 774px;
          }

					.popular-article {
            max-width: 410px;
						// height: 80%;
						padding: 16px 0;
						position: sticky;
  					top: 7.2rem;
						left: 0;
						background: #fff;
          }

          .small {
            border: none;
            padding: 16px 0;
          }

          .line {
						border-top: 0.5px solid #e0e0e0;
					}

					.line-bottom {
						border-bottom: 0.5px solid #e0e0e0;
					}

					.line-bottom-bold {
						border-bottom: 1px solid #000;
					}

					.line-bottom-bold:nth-child(5) {
						border: none;
					}

          .content {
            // margin: 16px;
          }

          .page-container {
						max-width: 100%;
						margin: 56px auto;

						max-width: 1280px;
						padding: 56px 78px 0 78px;
					}
        `}
			</style>
		</div>
	);

};

export default Desktop;
